import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import * as React from "react";
import styled from "styled-components";
import { Theme } from "../../components/core/app.theme";

const containerStyle = {
  width: "100%",
  height: "100%",
  filter: "grayscale(100%)",
};

const center = {
  lat: -37.81035014048652,
  lng: 144.9514626401549,
};

export const GoogleMaps = () => (
  <MapContainer>
    <MapWrapper>
      <LoadScript googleMapsApiKey="AIzaSyCCkp5wjpwi7TeB2nSHLFmjhBDWoqAPebQ">
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
          <Marker position={center} />
        </GoogleMap>
      </LoadScript>
    </MapWrapper>
  </MapContainer>
);

const MapContainer = styled.div({
  display: "flex",
  justifyContent: "center",
});

const MapWrapper = styled.div({
  width: "100%",
  height: "300px",
  [Theme.media("lg")]: {
    width: "450px",
    height: "400px",
  },
});
