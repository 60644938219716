import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { SectionHero, SectionHeroImageCss } from "../../components/templates/page/section/section-hero";

export const ContactUsHero = () => (
  <SectionHero
    title={"Get in touch"}
    image={
      <StaticImage
        src={`../../assets/images/hero/hero-contact.png`}
        style={SectionHeroImageCss}
        placeholder="dominantColor"
        alt=""
      />
    }
  />
);
