import * as React from "react";
import { Paragraphs, Text } from "../../components/atoms/typography";
import { Responsive } from "../../components/gls/responsive";
import { StretchSpacer } from "../../components/gls/spacer";
import { Vertical } from "../../components/gls/vertical";
import { Section } from "../../components/templates/page/section/section";
import { GoogleMaps } from "./google-maps";

export const ContactUsSectionIntro = () => (
  <Section>
    <Responsive>
      <Vertical space={"lg"}>
        <Paragraphs>
          <Text fontFamily={"monument"} textTransform={"uppercase"} fontSize={"lg"}>
            Media
          </Text>
          <Text maxWidth={350}>
            If you are a member of the media and would like to get in touch with our team, please contact us via the
            email address below:
          </Text>
          <Text fontWeight={"bold"}>media@h2fa.com.au</Text>
        </Paragraphs>
        <Paragraphs>
          <Text fontFamily={"monument"} textTransform={"uppercase"} fontSize={"lg"}>
            Investors
          </Text>
          <Text maxWidth={350}>
            If you are a prospective investor and would like to get in touch with our team, please contact us via the
            email address below:
          </Text>
          <Text fontWeight={"bold"}>investors@h2fa.com.au</Text>
        </Paragraphs>
      </Vertical>
      <StretchSpacer />
      <Vertical>
        <GoogleMaps />
        <Vertical space={"2xs"}>
          <Text fontSize={"xs"} colorScheme={"primary"} fontWeight={"bold"} maxWidth={280}>
            42-48 Batman Street, West Melbourne VIC 3003 AUSTRALIA
          </Text>
          <Text fontSize={"xs"} colorScheme={"primary"} fontWeight={"bold"}>
            Reach out to us at{" "}
            <a style={{ color: "black" }} href="mailto:info@h2fa.com.au">
              info@h2fa.com.au
            </a>
          </Text>
        </Vertical>
      </Vertical>
    </Responsive>
  </Section>
);
