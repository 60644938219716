import * as React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useEmailClient } from "../../client/emailjs.client";
import { buttonBaseCss } from "../../components/atoms/button";
import { IconClose } from "../../components/atoms/icon";
import { Text } from "../../components/atoms/typography";
import { Theme } from "../../components/core/app.theme";
import { Responsive } from "../../components/gls/responsive";
import { Vertical } from "../../components/gls/vertical";
import { Input, TextArea } from "../../components/molecules/input-field";

export const ContactUsFormSection = () => {
  const { register, handleSubmit, reset } = useForm();
  const { onSubmit, submitting, result, setResult } = useEmailClient({ onReset: reset });
  return (
    <SectionForm>
      <SectionFormContent onSubmit={handleSubmit(onSubmit)}>
        <Vertical space={"xl"}>
          <Vertical space={"sm"}>
            <Text
              colorScheme={"ghostgumwhite"}
              fontSize={"lg"}
              textTransform={"uppercase"}
              fontFamily={"monument"}
              maxWidth={"300px"}
            >
              Let's connect
            </Text>
            {result && (
              <Notification onClose={() => setResult(undefined)}>
                <Text colorScheme={"ghostgumwhite"}>{result}</Text>
              </Notification>
            )}
          </Vertical>
          <Vertical>
            <TextInputField id={"name"} label={"NAME"} required {...register("name")} />
            <TextInputField id={"email"} label={"EMAIL"} type={"email"} required {...register("email")} />
            <TextInputField id={"subject"} label={"SUBJECT"} required {...register("subject")} />
            <TextAreaField id={"message"} label={"MESSAGE"} required {...register("message")} />
            <ActionsContainer>
              <Button type={"submit"} disabled={submitting}>
                SUBMIT FORM
              </Button>
            </ActionsContainer>
          </Vertical>
        </Vertical>
      </SectionFormContent>
    </SectionForm>
  );
};

const Notification = (props: { children: React.ReactNode; onClose: () => any }) => (
  <NotificationAlert>
    {props.children}
    <IconClose
      color={"white"}
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
      }}
      action={{
        as: "button",
        onClick: props.onClose,
      }}
    />
  </NotificationAlert>
);

const NotificationAlert = styled.div({
  position: "relative",
  padding: "var(--space-sm)",
  backgroundColor: "var(--color-icywaters400)",
});

const SectionForm = styled.section({
  backgroundColor: Theme.color("primary"),
});

const SectionFormContent = styled.form({
  margin: "0 auto",
  maxWidth: Theme.sizeContainer("md"),
  padding: "var(--space-xl) var(--space-md)",
});

const ActionsContainer = styled.div({
  [Theme.media("lg")]: {
    alignSelf: "flex-end",
  },
});

const Button = styled.button({
  ...buttonBaseCss,
  color: Theme.color("primary"),
  backgroundColor: Theme.color("white"),
  borderColor: "transparent",
  "&:focus:enabled": {
    borderColor: Theme.color("ghostgumgrey"),
    backgroundColor: Theme.color("white800"),
  },
  "&:hover:enabled": {
    borderColor: Theme.color("ghostgumgrey"),
    backgroundColor: Theme.color("white800"),
  },
  "&:active:enabled": {
    borderColor: Theme.color("ghostgumgrey"),
    backgroundColor: Theme.color("white800"),
  },
});

type Props = { label: string };

const TextInputField = React.forwardRef((props: Props & React.InputHTMLAttributes<HTMLInputElement>, ref?: any) => {
  const { label } = props;
  return (
    <Responsive horizontal={{ verticalAlign: "top" }}>
      <Label id={props.id} htmlFor={props.id} children={label} />
      <Input {...props} ref={ref} />
    </Responsive>
  );
});

const TextAreaField = React.forwardRef(
  (props: Props & React.TextareaHTMLAttributes<HTMLTextAreaElement>, ref?: any) => {
    const { label } = props;
    return (
      <Responsive horizontal={{ verticalAlign: "top" }}>
        <Label id={props.id} htmlFor={props.id} children={label} />
        <TextArea {...props} ref={ref} />
      </Responsive>
    );
  }
);

const Label = styled.label`
  font-size: var(--text-sm);
  font-weight: var(--font-weight-bold);
  width: 25%;
  color: white;
`;
