import * as React from "react";
import { Page } from "../components/templates/page/page";
import { ContactUsFormSection } from "../sections/contact-us/contact-us-form.section";
import { ContactUsHero } from "../sections/contact-us/contact-us-hero";
import { ContactUsSectionIntro } from "../sections/contact-us/contact-us-intro.section";

const ContactUs = () => (
  <Page title={"Contact us"}>
    <ContactUsHero />
    <ContactUsSectionIntro />
    <ContactUsFormSection />
  </Page>
);

export default ContactUs;
